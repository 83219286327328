<template>
  <div class="widget">
        <h2>Récapitulatif de l'année</h2>
        <!-- {{ series }} -->
        <div id="chart">
            <apexchart type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import apexchart from 'vue-apexcharts'
// import moment from 'moment'
export default {
    components:{  apexchart },
    watch: {
        updatable: {
            handler(){
                    this.initChart()
            },
            deep: true
        }
    },   
    mounted(){
        this.initChart()
    },
    data(){
        return{
            series: []
        }
    },
    methods: {
        initChart(){
            this.series = []
            this.series.push({
            name: 'Volume d\'eau servi (L)',
            type: 'column',
            data: this.historiques.map(item => ({
                    ...item,
                    month: new Date(item.date).getMonth(), // Format DD-MM-YYYY to YYYY-MM-DD
                    value: Number(item.volume), // Convert value to a number
                    })).reduce(
                        (acc, item) => {
                            acc[item.month] = acc[item.month] || {
                                month: item.month,
                                value: 0
                            }
                            acc[item.month].value += Math.floor(parseInt(item.value)/1000)
                            return acc
                        },
                        []
                ).sort((b,a) => b.month - a.month).map(item => item.value)
            })
            this.series.push({
                name: 'Recharges (FCFA)',
                type: 'line',
                data: this.recharges.map(item => ({
                   ...item,
                    month: new Date(item.date).getMonth(), // Format DD-MM-YYYY to YYYY-MM-DD
                    value: Number(item.prix), // Convert value to a number
                })).reduce(
                        (acc, item) => {
                            acc[item.month] = acc[item.month] || {
                                month: item.month,
                                value: 0
                            }
                            acc[item.month].value += item.value
                            return acc
                        },
                        []
                ).sort((b,a) => b.month - a.month).map(item => item.value)
            })
            
        }  
    },
    computed: {
        ...mapGetters({
            historiques: 'host/historiques',
            recharges: 'host/recharges'
        }),
        updatable(){return {historiques: this.historiques   }},
        chartOptions(){
            return {
                
                chart: {
                    id: 'year-overview',
                    type: 'line',
                },
                xaxis: {
                    //type: 'datetime',
                    categories: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aôut', 'Sept', 'Oct', 'Nov', 'Dec']
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#008FFB'
                    },
                    labels: {
                        style: {
                            colors: '#008FFB',
                        }
                    },
                    title: {
                        text: "Volume d'eau servi (L)",
                        style: {
                            color: '#008FFB',
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                },
                {
                    seriesName: 'Recharges (FCFA)',
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#00E396'
                    },
                    labels: {
                        style: {
                            colors: '#00E396',
                        }
                    },
                    title: {
                        text: "Recharges  (FCFA)",
                        style: {
                            color: '#00E396',
                        }
                    },
                },
                
                ],
            }
        },
    }

}
</script>

<style>

</style>