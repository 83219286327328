<template>
    <router-link :to="{name: 'hosts'}" class="widget quick-category">
        <div class="quick-category-head">
            <span class="quick-category-icon qc-primary rounded-circle">
                <i class="las la-shopping-cart"></i>
            </span>
            <div class="ml-auto">
                <div class="quick-comparison qcompare-success">
                    <span>20%</span>
                    <i class="las la-arrow-up"></i>
                </div>
            </div>
        </div>
        <div class="quick-category-content">
            <h3>{{hosts.length}}</h3>
            <p class="font-14 text-primary mb-0">Sites</p>
        </div>
        
    </router-link>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            hosts: 'host/hosts'
        })
    }
}
</script>