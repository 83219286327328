<template>
  <div class="layout-top-spacing">
    <div class="row">
      <div class="col-12 greetings">
        <h2> Bienvenu, {{ me.fullname }}</h2>
      </div>
      
    </div>
    <div class="row">
      <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <client-volume v-if="is_client"/>
        <client v-else/>
      </div>
      <div class="col-xl-3 col-lg-12 col-md-12 col-12 layout-spacing">
          <client-nearly-host v-if="is_client" />
        <host v-else/>
      </div>
      <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <order />
      </div>
      <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
        <sale />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <year-overview/>
      </div>
      
    </div>
    
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex'
import ClientVolume from '../components/home/clientVolume.vue'
import ClientNearlyHost from '../components/home/clientNearlyHost.vue'
import Host from '../components/home/host.vue'
import Order from '../components/home/order.vue'
// import User from '../components/home/user.vue'
import Sale from '../components/home/sale.vue'
import Client from '../components/home/client.vue'
import yearOverview from '../components/home/yearOverview.vue'

export default {
  name: 'Home',
  components: {ClientVolume, ClientNearlyHost, Host, Client, Order, Sale, yearOverview},
  computed: {
    ...mapGetters({
      is_partenaire: 'auth/is_partenaire',
      is_admin: 'auth/is_admin',
      is_client: 'auth/is_client',
      me: 'auth/me'
    })
  }
}
</script>
<style lang="scss">
  .greetings{
   margin-top: 10px;
   margin-bottom: 20px;
   h2{
    color: rgba(0,0,0,0.52);
   }
   
 }
</style>