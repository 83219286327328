<template>
    <a class="widget quick-category">
        <div class="quick-category-head">
            <span class="quick-category-icon qc-warning rounded-circle">
                <i class="las la-tint"></i>
            </span>
            <div class="ml-auto">
                <div class="quick-comparison qcompare-danger">
                    <span>10%</span>
                    <i class="las la-arrow-down"></i>
                </div>
            </div>
        </div>
        <div class="quick-category-content">
            <h3>{{volumeCurrentMonth}} <small>L</small></h3>
            <p class="font-14 text-warning mb-0">Consommation de {{ mois }}</p>
        </div>
    </a>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters({
            historiques: 'host/historiques'
        }),
        currentMonthActivities(){
            const currentMonth = moment().month();
            return [...this.historiques].filter(item => moment(item.date).month() === currentMonth)
        },
        volumeCurrentMonth(){
            return this.currentMonthActivities.reduce((acc, item) => acc + item.volume, 0)/1000
        },
        mois(){
            return moment().format('MMMM')
        }
    },
    
    
}
</script>