<template>
    <a class="widget quick-category">
        <div class="quick-category-head">
            <span class="quick-category-icon qc-success-teal rounded-circle">
                <i class="las la-wallet"></i>
            </span>
            <!-- <div class="ml-auto">
                <div class="quick-comparison qcompare-danger">
                    <span>10%</span>
                    <i class="las la-arrow-down"></i>
                </div>
            </div> -->
        </div>
        <div class="quick-category-content">
            <h3>{{volumeRestant}} <small>L</small></h3>
            <p class="font-14 text-success-teal mb-0">Volume Restant</p>
        </div>
    </a>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters({
            me: 'auth/me',
            isClient: 'auth/is_client'
        }),
        volumeRestant(){
            if(this.me !== null && this.isClient) return Math.floor(this.me.telephone/1000)
            return 0
        }
    },
    
    
}
</script>