<template>
    <a class="widget quick-category">
        <div class="quick-category-head">
            <span class="quick-category-icon qc-secondary rounded-circle">
                <i class="las la-hand-holding-usd"></i>
            </span>
            <div class="ml-auto">
                <div class="quick-comparison qcompare-success">
                    <span>50%</span>
                    <i class="las la-arrow-up"></i>
                </div>
            </div>
        </div>
        <div class="quick-category-content">
            <h3>{{ coutCurrentMonth }} <small>Fcfa</small></h3>
            <p class="font-14 text-secondary mb-0">Recharges de {{ mois }}</p>
        </div>
    </a>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment'
export default {
    data() {
        return { }
    },
    computed: {
        ...mapGetters({
            recharges: 'host/recharges'
        }),
        rechargeCurrentMonth(){
            const currentMonth = moment().month();
            return [...this.recharges].filter(recharge => moment(recharge.date).month() === currentMonth)
        },
        coutCurrentMonth(){
            return this.rechargeCurrentMonth.reduce((acc, recharge) => acc + recharge.prix, 0)
        },
        rechargeLastMount(){
            const lastMonth = moment().month() - 1;
            return [...this.recharges].filter(recharge => moment(recharge.date).month() === lastMonth)
        },
        coutPreviousMonth(){
            return this.rechargeLastMount.reduce((acc, recharge) => acc + recharge.prix, 0)
        },
        mois(){
            return moment().format('MMMM')
        }
    }
}
</script>